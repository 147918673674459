<template>
  <div class="notice1">
    <div class="weeb_load basefont40"  v-if="error==0">加载中....</div>
    <template v-else-if="error==1">
        <div class="f_tit basefont30" >
            消息通知
        </div>
        <div class="cont">
            <div
              class="item"
              v-for="(item,index) in notice"
              @click="notinfo(item)" 
              :key="index">
                <div class="f_title">
                    <div class="tit">{{item.noticeTitle}}</div>
                    <div class="data">{{item.createDate}}</div>
                </div>
                <div class="text" v-html="item.noticeContent"></div>
            </div>
        </div>
        <el-pagination
        hide-on-single-page
        layout="prev, pager, next"
        :page-size= limit
        :current-page = page 
        @current-change="curpage"
        :total= total
        class="basepage" style="margin-top: 46px">
        </el-pagination>
    </template>
    <div class="weeb_load basefont40" v-else>暂时没有消息通知</div>
    
  </div>
</template>

<script>

export default {
  name: "Mynotice",
  data(){
    return {
        notice:null,
        error: 0,
        page: 1, //页数
        limit: 6, //条数
        total: 10,
    }
  },
  created:function () {
  },
  mounted(){
    this.getNotList();
  },
  methods: {
    getNotList(){
      
      this.$axios.post('/api2/portal/auth/contact/notice/findByCondition',{
          current: this.page,  //当前页数
          size: this.limit,  //每页条数
      }).then(res => {
          if(res.data.data.length){
              this.notice = res.data.data;
              this.total = res.data.total;
              this.error=1;
          }else{
            this.error = 3;
          }
      }).catch(error => {
          console.log(error);
      });
    },
    curpage(val){
          this.page=val;
          this.$nextTick( () => {
              this.getNotList();
          });
    },
    notinfo(obj){  //跳转详情
        let str = JSON.stringify(obj);
        sessionStorage.setItem("notinfo",str);
        this.$router.push({name: "Mynoticeinfo"});
    }
  },
  computed:{
    
  }
}
</script>
